import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Paginator from '../../CustomComponents/Paginator'

import { fetchDataUsingAxios } from '../../../actions/tableActions';
import {saveSearchValue} from '../../../actions/searchActions';
import ServerError from '../../CustomComponents/ServerError';

import Tables from './Tables.js'

class Pagination extends Component {
  constructor(props){
    super(props);
    this.resetThePagination = false;
    this.optionsToSelect = [
      { value: 15 , label: 15 },
      { value: 30 , label: 30},
      { value: 35 , label: 35 },
      { value: 40 , label: 40 }
    ];
    this.selectedOption = {value: 15 , label: 15};
    this.state = {
        limit: this.props.limit,
        selected: 0,
        forcePage: false,
        offset: this.props.offset
    };
  }
  handlePageClick(data){
      let selected = data.selected;
      let offset = Math.ceil(selected * this.state.limit);
      this.setState({
        selected: data.selected,
        forcePage: false,
        offset: offset
      });
      this.props.dispatch(fetchDataUsingAxios(this.state.limit,offset, this.props.search.searchTerm.selectedField, this.props.search.searchTerm.query));
  }
  UNSAFE_componentWillUpdate(){
    if(this.props.search.searchTerm.resetThePagination){
      this.setState({
        limit:this.props.limit,
        offset:this.props.offset
      })
      this.resetThePagination =  true
    }
  }
  componentDidMount() {
    this.props.dispatch(fetchDataUsingAxios());
  }
  componentDidUpdate(){
    let searchValues  = {...this.props.search.searchTerm};

    searchValues.resetThePagination = false;
    if(this.props.search.searchTerm.resetThePagination) this.props.dispatch(saveSearchValue(searchValues));
    this.resetThePagination =  false;
  }
  setRowsPerPage = (data) => {
    this.setState({
      limit: data,
      forcePage : true,
      offset: 0
    });
    this.props.dispatch(fetchDataUsingAxios(data, 0, this.props.search.searchTerm.selectedField, this.props.search.searchTerm.query));
  }
  render() {
    let totalCount = this.props.items && this.props.items.total, pageCount = Math.ceil(totalCount / this.state.limit),
      inputProps = {
        totalCount : totalCount,
        offset : this.state.offset,
        limit :  this.state.limit,
        uniqueId : 1,
        optionsToSelect : this.optionsToSelect,
        selectedOption : this.selectedOption,
        resetThePagination : this.resetThePagination
      };
    if(this.state.forcePage || this.props.search.searchTerm.resetThePagination){
      inputProps.forcePage = 0;
    }
    return (
      <div className="commentBox">
        { !this.props.error ?
          <>
            <Tables rowsPerPage ={this.state.limit}/>
            { totalCount > 0 &&
              <Paginator previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick.bind(this)}
                containerClassName={"pagination react-paginate"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                {...inputProps}
                setRowsPerPage = {this.setRowsPerPage}
              />
            }
          </>
        : <ServerError/> }
      </div>
      
    );
  }
}
Pagination.defaultProps ={
  offset: 0,
  limit:15
}
Pagination.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.object,
  error: PropTypes.bool,
  dispatch: PropTypes.func,
  pageCount: PropTypes.number,
  limit: PropTypes.number,
  offset: PropTypes.number,
  totalCount: PropTypes.number
}
function mapStateToProps(state){
	return {
    items: state.tables.items.data,
    search: state.search,
    loading: state.tables.loading,
    error: state.tables.error
  }
}

export default connect(mapStateToProps)(Pagination);
