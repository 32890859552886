import * as C from '../constants';

const initialState = {
  items: [],
  loading: false,
  error: null
};

export default function UserInfoReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case C.USER_INFO_FETCH_DATA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case C.USER_INFO_FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.data,
        error: action.payload.data.data.hasOwnProperty('errorMessage') ? true : false
      };

    case C.USER_INFO_FETCH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };

    default:
      return state;
  }
}
