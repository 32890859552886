const baseUrl = "https://51es52yr38.execute-api.us-east-1.amazonaws.com/v1/"

const getAllDiscoStatusUrl = baseUrl + "getalldiscosstatus";
const getUsersCountUrl = baseUrl + "getactiveinactivecount";
const getUserInfoUrl = baseUrl + "getuserprofile";
const getUserDeviceInfoUrl = baseUrl + "getuserdeviceinfo";
const getDeviceCategoryCountUrl = baseUrl + "getdevicecategorycount";
const getAverageInternetSpeedUrl = baseUrl + "performancemetrics";
const getAverageDevicesLatencyUrl = baseUrl + "performancemetrics";

export {
  getAllDiscoStatusUrl,
  getUsersCountUrl,
  getUserInfoUrl,
  getUserDeviceInfoUrl,
  getDeviceCategoryCountUrl,
  getAverageInternetSpeedUrl,
  getAverageDevicesLatencyUrl
}
