import React, { Component } from 'react';
import PropTypes from 'prop-types';
import errorImg from '../../img/unable-server-graphic.png';

class ServerError extends Component{
  constructor(props){
    super(props);
    this.props = props;
  }
  handleRefresh(){
    location.reload();
  }
  render(){
    return(
      !this.props.version2 ?
      <div className="server-error-v1">
        <h2>Unable to Connect to the Server</h2>
        <img src={errorImg} />
          <p><button onClick={() => this.handleRefresh()}>TRY AGAIN</button></p>
      </div>
      : <div className="server-error-v2" style={{height: this.props.height || '300px'}}><p>Unable to Connect to the Server</p></div>
    )
  }
}
ServerError.propTypes = {
  version2: PropTypes.number,
  height: PropTypes.any
}

export default ServerError;