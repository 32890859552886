import React, { Component } from 'react';
import Select, {components} from 'react-select';
import PropTypes from 'prop-types';


const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
          <i className="material-icons">
              {props.selectProps.menuIsOpen ? "arrow_drop_up" : "arrow_drop_down"}
          </i>
      </components.DropdownIndicator>
    )
  );
};
const customStyles = {
  option: function(provided) {
      return {...provided, borderBottom: '1px solid #b7bfc9', backgroundColor: '#41454a', color: '#ffffff', cursor: 'pointer'}
  },
  singleValue: (provided) => {
    return {...provided};
  }
}
class CustomSelect extends Component {
  constructor(props){
    super(props);
    this.state = {
      optionsToSelect: this.props.optionsToSelect,
      selectedOption:  this.props.selectedOption
    };
  }
  handleSelectedOptionsChange = (selectedOption) => {
    this.setState({ selectedOption });
    this.props.setTheSelection(selectedOption.value);
  }
  onMenuOpen(){
  }
  onMenuClose(){        
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    let optionsToSelect  = nextProps.optionsToSelect.slice();
    for(let option in optionsToSelect){
      if(optionsToSelect[option].value >= nextProps.totalCount){
        optionsToSelect.splice(option+1);
        break;
      }
    }
    if(this.props.resetThePagination){
      this.setState({
        selectedOption:this.props.selectedOption,
        optionsToSelect: optionsToSelect
      })
    } else {
      this.setState({
        optionsToSelect: optionsToSelect
      })
    }
  }
  render() {
    return (
      <Select
        components={{ DropdownIndicator }}  
        styles={customStyles}
        className={`react-select-container ${this.props.className}`}
        classNamePrefix = "react-select"
        defaultValue = {this.state.selectedOption}  
        value={this.state.selectedOption}
        placeHolder={this.state.selectedOption}
        onChange={this.handleSelectedOptionsChange}
        onMenuOpen={this.onMenuOpen}
        // menuIsOpen = {true}
        onMenuClose ={this.onMenuClose}
        options={this.state.optionsToSelect}
        selectedValue = {this.state.optionsToSelect[0]}
      />
    )
  }
}
CustomSelect.propTypes = {
  items: PropTypes.array,
  error: PropTypes.bool,
  optionsToSelect: PropTypes.array,
  selectedOption: PropTypes.object,
  setTheSelection: PropTypes.func,
  resetThePagination: PropTypes.bool,
  className: PropTypes.string
}
export default CustomSelect;