import React, { Component } from 'react';
import { connect } from "react-redux";
import ReactTable from "react-table";
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import NoData from '../../../CustomComponents/NoData';
import ServerError from '../../../CustomComponents/ServerError';

class AverageInternetSpeed extends Component {
  render() {
    const columns = [
      {
        Header: "DATE",
        accessor: "created_at",
        sortable: false,
        className: "rt-td-column pl-4",
        Cell: function(props){
          return (Date.parse(props.value))? <span className="rt-td-column-data"><Moment format="ddd, MMM DD, YYYY">{props.value}</Moment></span> : 
                       <span className="rt-td-column-data">-</span>;
        },
        minWidth: 150
      },
      {
        Header: "UPLOAD SPEED",
        accessor: "uploadSpeed",
        sortable: false,
        className: "rt-td-column pl-4",
        Cell: cellInfo => ( 
          <div className="rt-td-column-data">
            <p className="rt-td-column-value average">Average: {cellInfo.original.avg_upload_speed ? cellInfo.original.avg_upload_speed + ' [Mb/s]': '-'} </p>
            <p className="rt-td-column-value max">Max: {cellInfo.original.max_upload ? cellInfo.original.max_upload + ' [Mb/s]' : '-'}</p>
            <p className="rt-td-column-value min">Min: {cellInfo.original.min_upload ? cellInfo.original.min_upload + ' [Mb/s]' : '-'}</p>
          </div>
        ),
        minWidth: 200
      },
      {
        Header: "DOWNLOAD SPEED",
        accessor: "downloadSpeed",
        sortable: false,
        className: "rt-td-column pl-4",
        Cell: cellInfo => ( 
          <div className="rt-td-column-data">
            <p className="rt-td-column-value average">Average: {cellInfo.original.avg_download_speed ? cellInfo.original.avg_download_speed + ' [Mb/s]': '-'} </p>
            <p className="rt-td-column-value max">Max: {cellInfo.original.max_download ? cellInfo.original.max_download + ' [Mb/s]' : '-'}</p>
            <p className="rt-td-column-value min">Min: {cellInfo.original.min_download ? cellInfo.original.min_download + ' [Mb/s]' : '-'}</p>
          </div>
        ),
        minWidth: 200
      },
      {
        Header: "BANDWIDTH DEMAND",
        accessor: "bandwidthDemand",
        sortable: false,
        className: "rt-td-column pl-4",
        Cell: cellInfo => ( 
          <div className="rt-td-column-data">
            <p className="rt-td-column-value average">Average: {cellInfo.original.avg_bandwidth_demand ? cellInfo.original.avg_bandwidth_demand + ' [Mb/s]': '-'} </p>
            <p className="rt-td-column-value max">Max: {cellInfo.original.max_bandwidth ? cellInfo.original.max_bandwidth + ' [Mb/s]' : '-'}</p>
            <p className="rt-td-column-value min">Min: {cellInfo.original.min_bandwidth ? cellInfo.original.min_bandwidth + ' [Mb/s]' : '-'}</p>
          </div>
        ),
        minWidth: 200
      }
    ]
    let data = this.props.items ? this.props.items.data : [];
    return (
      <div>
        <h4 className="text-center mb-3 mt-5">INTERNET SPEED</h4>
        {
          !this.props.error ?
          <ReactTable
            className="-striped -highlight average-internet-speed-table"
            columns = {columns}
            data = {data}
            noDataText = {this.props.loading ? "" : <NoData/>}
            resizable
            minRows={7}
            showPagination = {false}
          />
        : <ServerError version2 height="620px"/>
        }
      </div>
    );
  }
}

AverageInternetSpeed.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.object,
  error: PropTypes.bool,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  guid: PropTypes.string,
  daysbefore: PropTypes.string
}
AverageInternetSpeed.defaultProps = {
  daysbefore: "7"
}
function mapStateToProps(state){
	return {
    items: state.AverageInternetSpeed.items.data,
    loading: state.AverageInternetSpeed.loading,
    error: state.AverageInternetSpeed.error
	}
}

export default connect(mapStateToProps)(AverageInternetSpeed);