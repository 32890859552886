import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import CustomSelect from '../../CustomComponents/Select';
import { fetchDataUsingAxios } from '../../../actions/tableActions';
import {saveSearchValue} from '../../../actions/searchActions';

class Search extends Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedField: 'All',
        query: '',
        resetThePagination: false
      };
      this.setTheSelection = this.setTheSelection.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleInputSearch = this.handleInputSearch.bind(this);
      this.clearInputSearch = this.clearInputSearch.bind(this);
      this.saveTheSearchValues = this.saveTheSearchValues.bind(this);
      this.handleOnKeyPress = this.handleOnKeyPress.bind(this);
    }
    setTheSelection = (data) => {
      this.setState({
        selectedField: data,
      });
    }
    handleOnKeyPress(e){
      if(e.key === "Enter"){
        e.preventDefault();
        this.saveTheSearchValues();
      }
    }
    handleInputChange() {
      if(this.search.value.length > 0){
        this.clearSearch.classList.remove('d-none');
      }else{
        this.clearSearch.classList.add('d-none');
      }
    }
    handleInputSearch(e){
      e.preventDefault();
      this.saveTheSearchValues();
    }
    clearInputSearch(e){
      e.preventDefault();
      this.search.value = '';
      this.saveTheSearchValues();
      this.clearSearch.classList.add('d-none');
    }
    saveTheSearchValues(){
      this.setState({
        query: this.search.value,
        resetThePagination: true
      }, () => {
        this.props.dispatch(saveSearchValue(this.state));
        this.props.dispatch(fetchDataUsingAxios(15,0, this.state.selectedField, this.state.query));
      });
    }
    render(){
      let customSelectProps = {
        optionsToSelect : [
          { value: "ALL" , label: "All", className: "all" },
          { value: "Username" , label: "Username", className: "username"},
          { value: "DiscoGUID" , label: "Disco GUID", className: "discoguid" }
        ],
        selectedOption : { value: "ALL" , label: "All" },
        className : "search"
      }
      return(
        <div className="search-component">
            <CustomSelect 
              {...customSelectProps}
              setTheSelection = {this.setTheSelection}
            />
            <form className="search-container" id="addItemForm">
              <input
                type="text"
                className="input-field"
                id="inputField"
                placeholder=""
                ref={input => this.search = input}
                onChange={this.handleInputChange}
                onKeyPress = {this.handleOnKeyPress}
              />
              <span id="searchclear" className="search-clear d-none" ref={span => this.clearSearch = span} onClick={this.clearInputSearch}>
                <i className="material-icons">clear</i>
              </span>
              <button type="button" className="button button-info button-field" onClick={this.handleInputSearch}>
                <i className="material-icons">search</i>   
              </button>
            </form>
        </div>            
      )
    }
}

Search.propTypes = {
    items: PropTypes.array,
    error: PropTypes.bool,
    dispatch: PropTypes.func,
    search: PropTypes.func
};

const mapStateToProps = state => ({
    searchValue: state.search.searchTerm
  });

export default connect(mapStateToProps)(Search);











