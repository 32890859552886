import axios from 'axios';
import * as service from './serviceUrls'
import { serializeTheQueryString } from '../utils/utils'

const headers = {
  headers: {
    'x-api-key': 'THZqnHbeQX5wAFMrzEg9T61kENIAJmjA1VozUYkJ'
  }
};

const getAllDiscoStatus = (limit, offset, statusField, queryString) => {
  return axios
    .get(`${service.getAllDiscoStatusUrl}?${serializeTheQueryString({n:`${limit}`, p:`${offset}`, sf: `${statusField.toLowerCase()}`, s:`${queryString}`})}`, headers)
    .then(response => response)
    .catch(error => {
      throw error;
    });
};

const getUsersCount = () => {
  return axios
    .get(service.getUsersCountUrl, headers)
    .then(response => response)
    .catch(error => {
      throw error;
    });
};

const getUserInfo = (guid) => {
  return axios
    .get(`${service.getUserInfoUrl}?${serializeTheQueryString({guid:`${guid}`})}`, headers)
    .then(response => response)
    .catch(error => {
      throw error;
    });
};

const getUserDeviceInfo = (limit, offset, guid) => {
  return axios
    // .get('../json/empty.json')
    .get(`${service.getUserDeviceInfoUrl}?${serializeTheQueryString({n:`${limit}`, p:`${offset}`, guid: `${guid}`})}`, headers)
    .then(response => response)
    .catch(error => {
      throw error;
    });
};

const getDeviceCategoryCount = (guid) => {
  return axios
    .get(`${service.getDeviceCategoryCountUrl}?${serializeTheQueryString({guid:`${guid}`})}`, headers)
    .then(response => response)
    .catch(error => {
      throw error;
    });
};

const getAverageInternetSpeed = (guid, daysbefore) => {
  return axios
    .get(`${service.getAverageInternetSpeedUrl}?${serializeTheQueryString({guid:`${guid}`, daysbefore:`${daysbefore}`})}`, headers)
    .then(response => response)
    .catch(error => {
      throw error;
    });
};

const getAverageDevicesLatency = () => {
  return axios
  .get('../json/AverageDevicesLatency.json')
    // .get(`${service.getAverageDevicesLatencyUrl}?${serializeTheQueryString({guid:`${guid}`, daysbefore:`${daysbefore}`})}`, headers)
    .then(response => response)
    .catch(error => {
      throw error;
    });
};

export {
  getAllDiscoStatus,
  getUsersCount,
  getUserInfo,
  getUserDeviceInfo,
  getDeviceCategoryCount,
  getAverageInternetSpeed,
  getAverageDevicesLatency
};
