import { combineReducers } from 'redux';
import tables from './TablesReducer';
import usersCount from './UsersCountReducer';
import search from './SearchReducer';
import userInfo from './UserInfoReducer';
import userOverviewTable from './UserOverviewTableReducer';
import UserInfoCharts from './UserInfoChartsReducer';
import donutbarTooltip from './donutbarTooltipReducer'
import AverageInternetSpeed from './AverageInternetSpeedReducer';
import AverageDevicesLatency from './AverageDevicesLatencyReducer';

const rootReducer = combineReducers({
  tables,
  usersCount,
  search,
  userInfo,
  userOverviewTable,
  UserInfoCharts,
  donutbarTooltip,
  AverageInternetSpeed,
  AverageDevicesLatency
});

export default rootReducer;
