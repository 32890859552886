import React from 'react';
import Pagination from './Pagination';
import UsersCount from './UsersCount';
import Subheader from '../../CustomComponents/Subheader';

const HomePage = () => {
  return (
    <div>
      <Subheader text="Disco Dashboard" />
      <div className="container">
        <UsersCount />
        <Pagination />
      </div>
    </div>
  );
};

export default HomePage;
