import * as C from '../constants';

const initialState = {
  searchTerm: {
    selectedField : "ALL",
    query: ''
  }
}

export default function SearchReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case C.SEARCH:
      return {
          searchTerm : action.payload.data
      };
    default:
      return state;
  }
}
