import React, { Component } from 'react';
import discologo from '../../img/disco-logo.png';
import asurionlogo from '../../img/asurion-logo.png';
import menu from '../../img/menu.png';

class Header extends Component {
	
	componentDidMount() {
		const openMenu = $('#openMenu');
		const closeMenu = $('#closeMenu');
		const menu = $('#menu');
		const list = menu.find('li');

		openMenu.click(function(){
			menu.removeClass('d-none');
		});
		
		closeMenu.click(function(){
			menu.addClass('d-none');
		});

		list.click(function(e){
			Object.keys(list).map(function(keyName) {
				if($(list[keyName]).hasClass('selected')){
					$(list[keyName]).removeClass('selected');
				}
			});
			$(e.target).addClass('selected');
		});
	}
	render(){
		return(
			<div className="header-wrapper">
				<div className="header">
					<div className="logo-wrapper">
						<img className="disco-logo" src={discologo} width="115" height="41" />
						<img className="asurion-logo" src={asurionlogo} width="74" height="18" />
						{/* <img className="menu-icon-open" id="openMenu" src={menu} width="37" height="25" /> */}
					</div>
					<div id="menu" className="menu d-none">
						<img className="menu-icon-close" id="closeMenu" src={menu} width="37" height="25" />
						<ul className="menu-list">
							<li>KPI</li>
							<li className="selected">DISCO PUCK</li>
							<li>SDK</li>
							<li>Help</li>
							<li>Settings</li>
							<li>Logout</li>
						</ul>
					</div>
				</div>
			</div>
			
		)
	}

}

export default Header;