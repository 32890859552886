import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Paginator from '../../../CustomComponents/Paginator'

import { fetchDataUsingAxios } from '../../../../actions/userOverviewTableActions';
import UserOverviewTable from './UserOverviewTable';
import ServerError from '../../../CustomComponents/ServerError';

class UserOverviewPagination extends Component {
  constructor(props){
    super(props);
    this.resetThePagination = false;
    this.optionsToSelect = [
      { value: 15 , label: 15 },
      { value: 30 , label: 30},
      { value: 35 , label: 35 },
      { value: 40 , label: 40 }
    ];
    this.selectedOption = {value: 15 , label: 15};
    this.state = {
        limit: this.props.limit,
        selected: 0,
        forcePage: false,
        offset: this.props.offset
    };
  }
  handlePageClick(data){
      let selected = data.selected;
      let offset = Math.ceil(selected * this.state.limit);
      this.setState({
        selected: data.selected,
        forcePage: false,
        offset: offset
      });
      this.props.dispatch(fetchDataUsingAxios(this.state.limit,offset, this.props.guid));
  }
  componentDidMount() {
    this.props.dispatch(fetchDataUsingAxios(15,0 , this.props.guid));
  }
  setRowsPerPage = (data) => {
    this.setState({
      limit: data,
      forcePage : true,
      offset: 0
    });
    this.props.dispatch(fetchDataUsingAxios(data, 0, this.props.guid));
  }
  render() {
    let {deviceCount, puckStatus, guid} = this.props;
    let totalCount = this.props.items && this.props.items.total, pageCount = Math.ceil(totalCount / this.state.limit),
        inputProps = {
          totalCount : totalCount,
          offset : this.state.offset,
          limit :  this.state.limit,
          uniqueId : 1,
          optionsToSelect : this.optionsToSelect, 
          selectedOption : this.selectedOption,
        },
        UserOverviewTableProps = {
          deviceCount : deviceCount,
          puckStatus: puckStatus,
          guid : guid,
          rowsPerPage: this.state.limit
        };
    if(this.state.forcePage){
      inputProps.forcePage = 0; 
    }
    return (
      !this.props.error ?
      <div className="user-overview-pagination">
        <UserOverviewTable {...UserOverviewTableProps}/>
        {totalCount > 0 && 
          <Paginator previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick.bind(this)}
            containerClassName={"pagination react-paginate"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"} 
            {...inputProps}
            setRowsPerPage = {this.setRowsPerPage}
          />
        }
      </div>
      : <ServerError version2 height="700px" />
    );
  }
}
UserOverviewPagination.defaultProps ={
  offset:0,
  limit: 15
}
UserOverviewPagination.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.object,
  error: PropTypes.bool,
  dispatch: PropTypes.func,
  rowsPerPage: PropTypes.number,
  pageCount: PropTypes.number,
  limit: PropTypes.number,
  offset:PropTypes.number,
  guid: PropTypes.string
}
function mapStateToProps(state){
	return {
    items: state.userOverviewTable.items.data,
    loading: state.userOverviewTable.loading,
    error: state.userOverviewTable.error
	}
}

export default connect(mapStateToProps)(UserOverviewPagination);
