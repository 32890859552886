import * as C from '../constants';

export const fetchDataSaved = data => ({
  type: C.SEARCH,
  payload: { data }
});

export function saveSearchValue(data) {
  return (dispatch) => {
      dispatch(fetchDataSaved(data));
  }
}
