import React, {Component} from "react";
import App from './App';
import {Authenticator} from 'aws-amplify-react';
import Amplify from 'aws-amplify';
// import {CustomSignIn} from './CustomComponents/Login' 

Amplify.configure({
  Auth: {
     identityPoolId: 'us-east-1:ef6b22fa-880a-42f4-b035-2be3c08222bb',
     region: 'us-east-1',
     identityPoolRegion: 'us-east-1',
     userPoolId: 'us-east-1_Mn6PC4QxL',
     userPoolWebClientId: '7cs4a0hl4teg2nvsek5abl5qui',
  }
});

class AppWithAuth extends Component {
  render(){
    return (
      <div>
        <Authenticator>
          <App/>
        </Authenticator>
      </div>
    );
  }
}

export default AppWithAuth;