import React, { Component } from 'react';
import {Doughnut} from 'react-chartjs-2'
import PropTypes from 'prop-types';
import Chart from 'chart.js'

const doughnutChartLabels = ["smartHomeDevice", "entertainment", "computersPhonesPrinters", "homeSecurity", "networking", "other"];
const doughnutChartColors = [ "#0073a3", "#34c9d5", "#07716a", "#62a269", "#747f1a", "#cccd5d"];


class DonutchartView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels : doughnutChartLabels,
      backGroundColors : doughnutChartColors,
      dataArr : []
    }
  }
 UNSAFE_componentWillMount(){
  let propsData= this.props.data, clonedDataArr = [], labels = [], backGroundColors = [], dataArr = [];
    for(let d in propsData){
      if(propsData[d] !== 0 && d !== 'total' && d != "active" && d != "inactive"){
        let clonedObj = {};
        clonedObj['label'] = d;
        clonedObj['count'] = propsData[d];
        clonedDataArr.push(clonedObj);
      }
    }
    for(let obj of clonedDataArr){
        switch(obj.label){
          case "smartHomeDevice":
            labels.push("Smart Home Device");
            backGroundColors.push("#0073a3");
            dataArr.push(obj.count);
            break;
          case "entertainment":
            labels.push("Entertainment");
            backGroundColors.push("#34c9d5");
            dataArr.push(obj.count);
            break;
          case "computersPhonesPrinters":
            labels.push("Computer | Phone | Printer");
            backGroundColors.push("#07716a");
            dataArr.push(obj.count);
            break;
          case "homeSecurity":
            labels.push("Home Security");
            backGroundColors.push("#62a269");
            dataArr.push(obj.count);
            break;
          case "networking":
            labels.push("Networking");
            backGroundColors.push("#747f1a");
            dataArr.push(obj.count);
            break;
          case "other":
            labels.push("Other");
            backGroundColors.push("#cccd5d");
            dataArr.push(obj.count);
            break;
          default:
            labels.push(obj.label);
            backGroundColors.push("#cccd5d");
            dataArr.push(obj.count);
            break;
        }
    }
    this.setState({
      labels: labels,
      backGroundColors: backGroundColors,
      dataArr: dataArr
    })
    Chart.pluginService.register({
      beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
          var ctx = chart.chart.ctx;
          var centerConfig = chart.config.options.elements.center;
          var fontStyle = centerConfig.fontStyle || 'Roboto';
          var txt = centerConfig.text;
          ctx.font = "60px " + fontStyle;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
          var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
          ctx.font = '38px Arial';
          ctx.fillStyle = '#ffffff';
          ctx.fillText(txt, centerX, centerY-5);
          ctx.font= '14px Arial'
          ctx.fillText('Devices', centerX, centerY+20);
        }
      }
    });
  } 
  render(){
    const data = {
      labels: this.state.labels,
      datasets: [{
        data: this.state.dataArr,
        backgroundColor: this.state.backGroundColors,
        borderColor: '#3b4047',
        segmentShowStroke: false
      }]
    };
    return(
      <div>
        <Doughnut 
          data={data} 
          width={400}
          height={330}
          options={{
            responsive: true,
            cutoutPercentage : 75,
            rotation:120,
            legend: {
              display: false
            },
            tooltips:{
              displayColors : false,
              backgroundColor: 'rgba(0,0,0,0.8)',
              bodyFontFamily: 'Roboto Light'
          },
          elements: {
              center: {
                text: `${this.props.data.total}`,
                color: '#FF6384',
                fontStyle: 'Arial', 
                sidePadding: 20 
              },
            }
            
          }}

        />
      </div>
    )
  }
}

DonutchartView.propTypes = {
  data: PropTypes.object
};

export default DonutchartView;


