import { withRouter } from "react-router-dom";
import React, { Component } from 'react';
import { connect } from "react-redux";
import ReactTable from "react-table";
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { toCapitalize } from '../../../utils/utils';
import { fetchDataUsingAxios } from '../../../actions/tableActions';

import {saveSearchValue} from '../../../actions/searchActions';
import NoData from '../../CustomComponents/NoData';
import Spinner from '../../CustomComponents/Spinner';

import "react-table/react-table.css";

class Tables extends Component {
  handlePageClick(){
    this.props.dispatch(fetchDataUsingAxios())
  }
  handleClick(data){
    let searchDispatchInput = {
      selectedField: 'All',
      query: '',
      resetThePagination: false
    }
    this.props.dispatch(saveSearchValue(searchDispatchInput));
    this.props.history.push({
      pathname: '/useroverview/'+data.discoGuid,
      state:{deviceCount: data.deviceCount, puckStatus: data.puckStatus}
    })
  }
  render(){
    const columns = [
      {
        Header: "USERNAME",
        accessor: "user",
        sortable: false,
        className: "rt-td-column",
        Cell: function(props){
          return  <span className="userName rt-td-column-data align-left">{toCapitalize(props.value)}</span>;
        },
        minWidth: 300
      },
      {
        Header: "DISCO GUID",
        accessor: "discoGuid",
        sortable: false,
        className: "rt-td-column text-center",
        Cell: props => <span className='rt-td-column-data'>{props.value}</span>,
        minWidth: 500
      },
      {
        Header: "# OF DEVICES",
        accessor: "deviceCount",
        sortable: false,
        minWidth: 150,
        className: "rt-td-column text-center",
        Cell: props => <span className='rt-td-column-data'>{props.value}</span>
      },
      {
        Header: "LAST REPORTED",
        accessor: "lastStatusCheckOn",
        sortable: false,
        Cell: function(props){
          return (Date.parse(props.value))? <span className="last-status-check rt-td-column-data"><Moment format="MM/DD/YYYY">{props.value}</Moment></span> : 
                       <span className="last-status-check rt-td-column-data">NA</span>;
        },
        minWidth: 200,
        className: "rt-td-column text-center",
      },
      {
        Header: "DISCO STATUS",
        accessor: "puckStatus",
        sortable: false,
        Cell: cellInfo => (<div className="puck-status rt-td-column-data align-left position-relative">
                          <span className={cellInfo.value.toLowerCase() === 'active' ? 'active' : 'inactive'}></span>
                          <span className="rt-td-column-value">{toCapitalize(cellInfo.value)}</span>
                          <p className="arrowClick" onClick={() => this.handleClick(cellInfo.original)}><span></span></p>
                        </div>)
                        ,
        minWidth: 300,
        className: "rt-td-column"
      }
    ]
    let data = this.props.items ? this.props.items.data : [];
    return(
      <div className="main-table">
      {
        !this.props.error &&
        <ReactTable
          className="-striped -highlight"
          loading = {this.props.loading}
          columns = {columns}
          data = {data}
          pageSize = {this.props.rowsPerPage}
          loadingText = {<Spinner/>}
          noDataText = {this.props.loading ? "" : <NoData/>}
          resizable
          minRows= {10}
          showPagination = {false}
        />
      }
      </div>
    )
  }

}
Tables.defaultProps ={
  pages: 10
}
Tables.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.object,
  error: PropTypes.bool,
  dispatch: PropTypes.func,
  rowsPerPage: PropTypes.number,
  history: PropTypes.object
}
function mapStateToProps(state){
	return {
    items: state.tables.items.data,
    loading: state.tables.loading,
    error: state.tables.error
	}
}

export default withRouter(connect(mapStateToProps)(Tables));
