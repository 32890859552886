import React, {Component} from 'react';
import spinnerSvg from '../../img/spinner.svg';

class Spinner extends Component {
  constructor(props){
    super(props);
  }
  render(){
    let spinnerProps = {
      height :  this.props.height || '100px'
    }

    return(
      <div className="dashboard-spinner d-block">
        <img src={spinnerSvg} alt="reload" className="reload-svg" {...spinnerProps}/>
      </div>
    )
  }
}

export default Spinner;