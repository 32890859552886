import React, { Component } from 'react';
import "react-tabs/style/react-tabs.css";
import PropTypes from 'prop-types';

import Subheader from '../../CustomComponents/Subheader';
import UserInfo from '../UserOverview/UserInfo';
import { toCapitalize } from '../../../utils/utils'

import groupComputingActivateImg from '../../../img/group-computing-activate.png'
import groupEnterActivateImg from '../../../img/group-enter-activate.png'
import groupNetworkActivateImg from '../../../img/group-network-activate.png'
import groupSmartActivateImg from '../../../img/group-smart-activate.png'
import groupSecurityActivateImg from '../../../img/group-security-activate.png'
import groupOtherActivateImg from '../../../img/group-other-activate.png'
import groupComputingInActivateImg from '../../../img/group-computing-inactivate.png'
import groupEnterInActivateImg from '../../../img/group-enter-inactivate.png'
import groupNetworkInActivateImg from '../../../img/group-network-inactivate.png'
import groupSmartInActivateImg from '../../../img/group-smart-inactivate.png'
import groupSecurityInActivateImg from '../../../img/group-security-inactivate.png'
import groupOtherInActivateImg from '../../../img/group-other-inactivate.png'

class UserOverview extends Component {
    
  render(){
    let userInfoProps = {
      vendor : this.props.location.state.vendor,
      guid: this.props.location.state.guid,
      deviceCount : this.props.location.state.deviceCount,
      puckStatus: this.props.location.state.puckStatus,
      deviceStatus: this.props.location.state.deviceStatus,
      category: this.props.location.state.category
    }
    let imageLink = function(){
      if(userInfoProps.deviceStatus.toLowerCase() === "active") {
        switch(userInfoProps.category.toLowerCase()){
          case 'computers-phones-printers':
            return groupComputingActivateImg;
          case 'entertainment':
            return groupEnterActivateImg;
          case 'networking':
            return groupNetworkActivateImg;
          case 'smart home device':
            return groupSmartActivateImg;
          case 'home security':
            return groupSecurityActivateImg;
          default:
            return groupOtherActivateImg;
        }
      } else {
        switch(userInfoProps.category.toLowerCase()){
          case 'computers-phones-printers':
            return groupComputingInActivateImg;
          case 'entertainment':
            return groupEnterInActivateImg;
          case 'networking':
            return groupNetworkInActivateImg;
          case 'smart home device':
            return groupSmartInActivateImg;
          case 'home security':
            return groupSecurityInActivateImg;
          default:
            return groupOtherInActivateImg;
        }
      }
    };
    return(
      <div>
        <Subheader text="DEVICE SUMMARY" arrow={true}/>
        <div className="container">
          <div className="row padding-wrapper">
            <div className="col-3 user-info-wrapper">
              <UserInfo {...userInfoProps} />
            </div>
            <div className="col-9 device-summary-wrapper">
              <div className="row">
                <div className="col">
                  <div className="device-summary-section device-summary-tabs">
                    <span className="device-summary-span">DEVICE SUMMARY</span>
                  </div>
                  <div className="row">
                      <div className="col-md-3 image-col">
                        <img className="device-summary-image" src={imageLink()}/>
                      </div>
                      <div className="col-md-9">
                        <div className="category">{this.props.location.state.category}</div>
                        <div className="device-name">{this.props.location.state.deviceName}</div>
                        <div className="device-status">
                          <span className={this.props.location.state.deviceStatus.toLowerCase() === 'active' ? 'active' : 'inactive'}></span>
                          <span className="rt-td-column-value">{toCapitalize(this.props.location.state.deviceStatus)}</span>
                        </div>
                      </div>
                  </div>
                  <hr className="horizontal-line"/>
                  <div className="device-summary-details">
                    <div className="holder">
                      <p className="field">IP Address:</p>
                      <p className="value">192.168.10.197</p>
                    </div>

                    <div className="holder">
                      <p className="field">MAC Address:</p>
                      <p className="value">AC:87:A3:22:30:F5</p>
                    </div>

                    <div className="holder">
                      <p className="field">Vendor:</p>
                      <p className="value">Apple/MacBook PRO</p>
                    </div>

                    <div className="holder">
                      <p className="field">Operating System:</p>
                      <p className="value">OS 16</p>
                    </div>

                    <div className="holder">
                      <p className="field">Number of Devices:</p>
                      <p className="value">{this.props.location.state.deviceCount}</p>
                    </div>

                    <div className="holder">
                      <p className="field">Device ID(GUID):</p>
                      <p className="value">{this.props.location.state.guid}</p>
                    </div>

                    <div className="holder">
                      <p className="field">NetBIOS Name:</p>
                      <p className="value">AMAMACSP1CRG8WL</p>
                    </div>

                    <div className="holder">
                      <p className="field">Domain:</p>
                      <p className="value">WORKGROUP</p>
                    </div>    

                    <div className="holder">
                      <p className="field">Bonjour Name:</p>
                      <p className="value">AMAMACSP1CRG8WL</p>
                    </div>

                    <div className="holder">
                      <p className="field">DHCP:</p>
                      <p className="value">AMAMACSP1CRG8WL</p>
                    </div>

                    <div className="holder">
                      <p className="field">More Info:</p>
                      <p className="value">123456789-2432e33324</p>
                    </div>    
                  </div>         
                </div>
                <div className="col">
                  <div className="device-activity-section device-summary-tabs">
                    <span className="device-summary-span">DEVICE ACTIVITY</span>
                  </div>
                  <div className="row">
                      <div className="col-md-3 image-col">
                      <img className="device-summary-image" src={imageLink()}/>
                      </div>
                      <div className="col-md-9">
                        <div className="category">{this.props.location.state.category}</div>
                        <div className="device-name">{this.props.location.state.deviceName}</div>
                        <div className="device-status">
                          <span className={this.props.location.state.deviceStatus.toLowerCase() === 'active' ? 'active' : 'inactive'}></span>
                          <span className="rt-td-column-value">{toCapitalize(this.props.location.state.deviceStatus)}</span>
                        </div>
                      </div>
                  </div>
                  <hr className="horizontal-line"/>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

UserOverview.propTypes = {
  match: PropTypes.object
}

export default UserOverview;