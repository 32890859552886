import React, { Component } from 'react';
import {Line} from 'react-chartjs-2'
import PropTypes from 'prop-types';

class LineChartsView extends Component {
  constructor(props) {
    super(props);
  }
  render(){
    let className = `line-chart-section ${this.props.className}`
    let pointRadius = ((days) => {
      switch(days){
        case 14:
          return 3;
        case 30:
          return 2;
        default:
          return 5;
      }
    })(this.props.daysbefore);
    let colorCode = ((className) => {
      switch(className){
        case 'download-speed-line-charts':
          return '#86c064';
        case 'bandwidth-demand-line-charts':
          return '#8f98a6';
        default:
          return '#2898c6';
      }
    })(this.props.className);
    var data = {
      labels: this.props.labels,
      datasets: [{
        fill: false,
        data: this.props.data,
        tension: 0,
        borderColor: colorCode,
        borderWidth: 1.5,
        pointRadius: pointRadius,
        pointBackgroundColor: colorCode,
      }]
    };
    return(
      <div className = {className}>
        <Line 
          data={data} 
          width={250}
          height={200}
          options={{
            layout: {
              margin: {
                top: 10,
                bottom: 0
              }
            },
            scales: {
              xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'DATE',
                  fontColor: 'white'
                },
                gridLines: {
                  backgroundColor: 'green',
                  display: false,
                  color:colorCode,
                  zeroLineColor: 'red'
                },
                ticks: {
                  fontSize: 15,
                  fontColor: 'white'
                }
              }],
              yAxes: [{
                
                scaleLabel: {
                  display: true,
                  labelString: 'SPEED',
                  fontColor: 'white'
                },
                gridLines: {
                  drawBorder: false,
                  color: colorCode,
                  borderDash: [1,2],
                  borderDashOffset: 1
                },
                ticks: {
                  fontSize: 15,
                  fontColor: 'white',
                  autoSkip: true,
                  maxTicksLimit: 5
                },
                // afterFit: function(scale) {
                //   // scale.width = 200
                // }
              }]
            },
            legend: {
              display: false,
            },
            tooltips:{
              backgroundColor: '#676767',
              bodyFontFamily: 'Roboto',
              titleFontFamily: 'Roboto',
              custom: function(tooltip) {
                if (!tooltip) return;
                tooltip.displayColors = false;
              },
              callbacks: {
                label: function(tooltipItem) {
                  return tooltipItem.yLabel;
                },
                title: function() {
                  return;
                },
                labelColor: function(){
                  return {
                    backgroundColor: '#676767'
                  }
                }
              }
            }
          }}
        />
      </div>
    )
  }
}

LineChartsView.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string,
  labels: PropTypes.array,
  daysbefore: PropTypes.number
};

export default LineChartsView;


