import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Search from './Search';
import { fetchDataUsingAxios } from '../../../actions/usersCountActions';
import totalUsers from '../../../img/totaluser.png';
import activeUsers from '../../../img/activeuser.png';
import inactiveUsers from '../../../img/inactiveuser.png';

class UsersCount extends Component {
    componentDidMount() {
        this.props.dispatch(fetchDataUsingAxios())
    }
    render(){
        return(
            <div className="users-count-container clearfix">
                <div className="row">
                    <div className="col user-count-col">
                        <div className="users-count-wrapper">
                                <img src={totalUsers} alt="total users"/>
                                <div className="users-count-text-holder">
                                    <p className="text">TOTAL USERS</p>
                                    <p className="value">{ !this.props.error && this.props.items ? this.props.items[0].count + this.props.items[1].count : "-" }</p>
                                </div>
                            </div>
                            <div className="seperator"></div>
                            <div className="users-count-wrapper">
                                <img src={activeUsers} alt="active users"/>
                                <div className="users-count-text-holder">
                                    <p className="text">ACTIVE USERS</p>
                                    <p className="value">{ !this.props.error && this.props.items ? this.props.items[0].count : "-" }</p>
                                </div>
                            </div>
                            <div className="seperator"></div>
                            <div className="users-count-wrapper">
                                <img src={inactiveUsers} alt="inactive users"/>
                                <div className="users-count-text-holder">
                                    <p className="text">INACTIVE USERS</p>
                                    <p className="value">{ !this.props.error && this.props.items ? this.props.items[1].count : "-" }</p>
                                </div>
                            </div>
                        </div>
                        <div className="col search-col">
                            <Search />
                        </div>
                </div>
            </div>
        )
    }

}

UsersCount.propTypes = {
    items: PropTypes.array,
    error: PropTypes.bool,
    dispatch: PropTypes.func
};

const mapStateToProps = state => ({
    items: state.usersCount.items.data,
    error: state.usersCount.error
});

export default connect(mapStateToProps)(UsersCount);
