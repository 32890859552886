import * as C from '../constants';
import { getAllDiscoStatus } from '../services';

export const fetchDataBegin = () => ({
  type: C.TABLE_FETCH_DATA_BEGIN
});

export const fetchDataSuccess = data => ({
  type: C.TABLE_FETCH_DATA_SUCCESS,
  payload: { data }
});

export const fetchDataFailure = error => ({
  type: C.TABLE_FETCH_DATA_FAILURE,
  payload: { error }
});

export function fetchDataUsingAxios(limit=15, offset=0, statusField="ALL",queryString='') {
    return (dispatch) => {
        dispatch(fetchDataBegin());

          getAllDiscoStatus(limit, offset,statusField, queryString)
          .then( response =>{
                dispatch(fetchDataSuccess(response))
            })
          .catch(error => {
            dispatch(fetchDataFailure(error));
          })
    }
}
