import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import arrowLeft from '../../img/arrow-left.png'

class SubHeader extends Component {
  handleClick(){
    this.props.history.goBack();
  }

	render(){
		return(
			<div className="subheader-wrapper">
				{
          this.props.text && 
          <div className="container position-relative">
            <div className="subheader">
              <p>{this.props.text}</p>
            </div>
          { 
            this.props.arrow && 
            <img className="arrow-left" src={arrowLeft} onClick={() => this.handleClick()} />
          }
          </div>
				}
			</div>
		)
	}
}

SubHeader.propTypes = {
  text: PropTypes.string,
  arrow: PropTypes.bool,
  href: PropTypes.string,
  history: PropTypes.object
}

export default withRouter(SubHeader);