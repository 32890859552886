import * as C from '../constants';

const initialState = {
  tooltipStatus: {
    status : false,
    count: 0,
    d:0
  }  
}

export default function SearchReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case C.TOOLTIP_STATUS:
      return {
        tooltipStatus : action.payload.data
      };
    default:
      return state;
  }
}
