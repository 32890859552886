import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { fetchDataUsingAxios } from '../../../actions/userInfoActions';

class UserInfo extends Component {
  componentDidMount() {
    this.props.dispatch(fetchDataUsingAxios(this.props.guid))
  }
  render(){
    return(
      <div className="user-info">
        <div> 
          <h4>User Information</h4>
          <div className="holder">
            <p className="field">Name:</p>
            <p className="value"> { !this.props.error && this.props.items && this.props.items.Name ? this.props.items.Name : '-'}</p>
          </div>

          <div className="holder">
            <p className="field">Email:</p>
            <p className="value">{ !this.props.error && this.props.items && this.props.items.Email ? this.props.items.Email : '-'}</p>
          </div>

          <div className="holder">
            <p className="field">Disco GUID:</p>
            <p className="value">{ !this.props.error && this.props.guid ? this.props.guid : '-'}</p>
          </div>

          <div className="holder">
            <p className="field">Phone Number:</p>
            <p className="value">{ !this.props.error && this.props.items && this.props.items.PhoneNumber ? this.props.items.PhoneNumber : '-'}</p>
          </div>

          <div className="holder">
            <p className="field">Number of Devices:</p>
            <p className="value">{ !this.props.error && this.props.deviceCount ? this.props.deviceCount : '-'}</p>
          </div>

          <div className="holder">
            <p className="field">Disco Status:</p>
            <p className="value">{ !this.props.error && this.props.puckStatus ? <><span className={this.props.puckStatus.toLowerCase() === 'active' ? 'active' : 'inactive'}></span>{this.props.puckStatus}</> : '-'}</p>
          </div>

          <div className="holder">
            <p className="field">Location of Disco:</p>
            <p className="value">{ !this.props.error && this.props.items && this.props.items.Location ? this.props.items.Location : '-'}</p>
          </div>

          <div className="holder">
            <p className="field">Zipcode:</p>
            <p className="value">{ !this.props.error && this.props.items && this.props.items.Zipcode ? this.props.items.Zipcode : '-'}</p>
          </div>

          <div className="holder">
            <p className="field">Provider:</p>
            <p className="value">{ !this.props.error && this.props.items && this.props.items.Provider ? this.props.items.Provider : '-'}</p>
          </div>

          <div className="holder">
            <p className="field">Other Info:</p>
            <p className="value"> - </p>
          </div>
        </div>
      </div>
    )
  }

}

UserInfo.propTypes = {
  guid: PropTypes.string,
  items: PropTypes.object,
  error: PropTypes.bool,
  dispatch: PropTypes.func,
  deviceCount: PropTypes.number,
  puckStatus: PropTypes.string
};

function mapStateToProps(state){
  return {
    items: state.userInfo.items.data,
    error: state.userInfo.error,
    loading: state.userInfo.loading
  }
}
export default connect(mapStateToProps)(UserInfo);