import React from "react";
import { SignOut } from "aws-amplify-react";
import {Auth} from 'aws-amplify';

class CustomGreetings extends SignOut {
  constructor(props){
    super(props);
    this._validAuthStates = ['signedIn'];
    this.state = {
      userName : ''
    }
  }
  componentDidMount(){
    this.setState({
      // userName: this.props.authData.signInUserSession.idToken.payload.name
      userName: this.props.authData.username
    })
  }
  signOut() {
    const { onStateChange } = this.props;
    Auth.signOut().then(() => {
              onStateChange('signedOut');
    });
  }
  render(){
    return (
      <div className="greetings-navbar">
            <span className="greetings-text">Hello {this.state.userName}</span>
            <button className="signout-button" onClick={this.signOut}>SIGN OUT</button>
      </div>
    );
  }
}

export default CustomGreetings;