import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import CustomSelect from '../CustomComponents/Select';
import PropTypes from 'prop-types';

class Paginator extends Component {
  constructor(props){
    super(props);
    this.state ={
        optionsToSelect: this.props.optionsToSelect,
        selectedOption: this.props.selectedOption
    };
    this.setTheSelection = this.setTheSelection.bind(this);
  }
  handleSelectedOptionsChange = (selectedOption) => {
    this.setState({ selectedOption });
    this.props.setRowsPerPage(selectedOption.value);
  }
  setTheSelection = (data) => {
    this.setState({
      selectedField: data,
    });
    this.props.setRowsPerPage(data);
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    let optionsToSelect  = this.state.optionsToSelect.slice();
    for(let option in optionsToSelect){
      if(optionsToSelect[option].value >= nextProps.totalCount){
        optionsToSelect.splice(option+1);
        break;
      }
    }
    if(nextProps.resetThePagination){
        this.setState({
          selectedOption:this.props.selectedOption,
          optionsToSelect: optionsToSelect
        })
    } else {
      this.setState({
        optionsToSelect: nextProps.optionsToSelect
      })
    }
  }
  render() {
    let customSelectProps = {
      optionsToSelect : this.state.optionsToSelect,
      selectedOption : this.state.selectedOption,
      className : "pagination-select",
      resetThePagination: this.props.resetThePagination,
      totalCount: this.props.totalCount
    }
      let newProps = {
        totalCount : this.props.totalCount
      }, toValue = this.props.totalCount > (this.props.offset + this.props.limit) ? (this.props.offset + this.props.limit) : this.props.totalCount;
      return (
      <div className="custom-react-paginator" key={this.props.uniqueId}>
      <span className="pageNumber">
        View {this.props.offset +1 } - {toValue} of {this.props.totalCount}
      </span>
        <ReactPaginate
          {...this.props}
          {...newProps}
        >
        </ReactPaginate>
        <span className="rows-per-page">Rows Per Page</span>
        <CustomSelect 
          {...customSelectProps}
          setTheSelection = {this.setTheSelection}
        />
      </div>
      
    )
  }
}
Paginator.propTypes = {
  items: PropTypes.array,
  resetThePagination: PropTypes.bool
}
export default Paginator;