import * as C from '../constants';
import { getUserInfo } from '../services';

export const fetchDataBegin = () => ({
  type: C.USER_INFO_FETCH_DATA_BEGIN
});

export const fetchDataSuccess = data => ({
  type: C.USER_INFO_FETCH_DATA_SUCCESS,
  payload: { data }
});

export const fetchDataFailure = error => ({
  type: C.USER_INFO_FETCH_DATA_FAILURE,
  payload: { error }
});

export function fetchDataUsingAxios(guid="") {
    return (dispatch) => {
        dispatch(fetchDataBegin());

        getUserInfo(guid)
          .then( response =>{
                dispatch(fetchDataSuccess(response))
            })
          .catch(error => {
            dispatch(fetchDataFailure(error));
          })
    }
}
