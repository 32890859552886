import React, { Component } from 'react';
import { connect } from "react-redux";
import ReactTable from "react-table";
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { toCapitalize } from '../../../../utils/utils';
import NoData from '../../../CustomComponents/NoData';
import ServerError from '../../../CustomComponents/ServerError';

class AverageDevicesLatency extends Component {
  getLatencyTextColor(value){
    let color;
    switch(value){
      case "poor":
        color = "#e56900";
        break;
      case "fair":
        color = "#b29900";
        break;
      case "good":
        color = "#8da64f";
        break;
      default:
        color = "#000";
        break;
    }
    return color;
  }
  getLatencyBackgroundColor(value){
    let color;
    switch(value){
      case "poor":
        color = "#fcf6f0";
        break;
      case "fair":
        color = "#fffff5";
        break;
      case "good":
        color = "#f8fdf6";
        break;
      default:
        color = "#fff";
        break;
    }
    return color;
  }
  render() {
    const columns = [
      {
        Header: "DATE",
        accessor: "date",
        sortable: false,
        className: "rt-td-column pl-4",
        Cell: function(props){
          return (Date.parse(props.original.created_at_from)) ? 
                  <div className="rt-td-column-data">
                    <p className="rt-td-column-value">From <Moment format="ddd, MMM DD">{props.original.created_at_from}</Moment></p>
                    <p className="rt-td-column-value">to <Moment format="ddd, MMM DD, YYYY">{props.original.created_at_to}</Moment></p>
                  </div> : 
                  <div className="rt-td-column-data">-</div>
        },
        minWidth: 200
      },
      {
        Header: "CATEGORIES",
        accessor: "category",
        sortable: false,
        className: "rt-td-column pl-4",
        minWidth: 220
      },
      {
        Header: "DEVICE NAME",
        accessor: "deviceName",
        sortable: false,
        className: "rt-td-column pl-4",
        minWidth: 300
      },
      {
        Header: "MAC ADDRESS",
        accessor: "macAddress",
        sortable: false,
        className: "rt-td-column content-center",
        minWidth: 200
      },
      {
        Header: "LATENCY",
        accessor: "latency",
        sortable: false,
        className: "rt-td-column content-center",
        Cell: props => <span className="rt-td-column-data" style={{color:this.getLatencyTextColor(props.value)}}>{toCapitalize(props.value)}</span>,
        minWidth: 100
      }
    ]

    const tableRowProps = {
      getTrProps: (s,rowInfo) => {
        let color = "#fff";
        if(rowInfo){
          color = this.getLatencyBackgroundColor(rowInfo.original.latency);
        }
        return {
          style: {
            backgroundColor: color
          }
        };
      }
    }

    let data = this.props.items ? this.props.items : [];
    return (
      <div>
        <h4 className="text-center mt-5 mb-0">AVERAGE DEVICES LATENCY</h4>
        <span className="total-number-of-device">Total Number of Devices: {this.props.deviceCount ? this.props.deviceCount : '-'}</span>
        {
          !this.props.error ?
          <ReactTable
            className="-striped -highlight average-devices-latency-table"
            loading = {this.props.loading}
            columns = {columns}
            data = {data}
            noDataText = {this.props.loading ? "" : <NoData/>}
            resizable
            minRows={5}
            showPagination = {false}
            {...tableRowProps}
          />
        : <ServerError version2 height="480px"/>
        }
      </div>
    );
  }
}

AverageDevicesLatency.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.array,
  error: PropTypes.bool,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  guid: PropTypes.string,
  deviceCount: PropTypes.number,
  daysbefore: PropTypes.string
}
AverageDevicesLatency.defaultProps = {
  daysbefore: "7"
}
function mapStateToProps(state){
	return {
    items: state.AverageDevicesLatency.items.data,
    loading: state.AverageDevicesLatency.loading,
    error: state.AverageDevicesLatency.error
	}
}

export default connect(mapStateToProps)(AverageDevicesLatency);