import React, { Component } from 'react';
import activeuserIcon from '../../../../img/activeuser-icon.png'
import inactiveuserIcon from '../../../../img/inactiveuser-icon.png'
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { fetchDataUsingAxios } from '../../../../actions/userInfoChartsActions';
import {ProgressBar} from 'react-bootstrap'
import Moment from 'react-moment';
import DoughnutChartView from './DoughnutChartView';
import ServerError from '../../../CustomComponents/ServerError';

class UserInfoCharts extends Component {
  componentDidMount() {
    this.props.dispatch(fetchDataUsingAxios(this.props.guid))
  }
  render(){
    return(
      <div className="user-charts-wrapper">
        { !this.props.error ?
          <div className="row user-info-charts-section">
            <div className="col-md-3 pie-chart-section">
              { this.props.items && 
                <DoughnutChartView 
                  data={this.props.items.counts}
                  width={330}
                  height={300}
                  margin={{top: 10, bottom: 10, left: 100, right: 100}}
                  outerRadius ={100}
                  innerRadius={75}
                  cornerRadius={2}
                />
              }
            </div>
            <div className="col-md-9 progress-bar-section">
              <div className="row">
                <div className="col-md-6 active-inactive-count">
                  <div className="row">
                    <div className="col active-devices">
                      <img src={activeuserIcon} className="active-user-icon"/>
                      <span>ACTIVE DEVICES</span>
                    </div>
                    <div className="col inactive-devices">
                      <img src={inactiveuserIcon} className="inactive-user-icon"/>
                      <span>INACTIVE DEVICES</span>
                    </div>
                  </div>
                  <div className="row device-count">
                    <div className="col active-device-count">
                      {this.props.items ? this.props.items.counts.active : '-'}
                    </div>
                    <div className="col inactive-device-count">
                      {this.props.items ? this.props.items.counts.inactive : '-'}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 last-updated-date right-align">
                  {this.props.items && this.props.items.lastUpdatedOn && 
                    <span>Last Updated <Moment format="MMM DD, YYYY hh:mm A">{this.props.items.lastUpdatedOn}</Moment></span>
                  }
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="col-md-8 ">
                      Smart Home Device 
                    </div>
                    <div className="col-md-4  right-align device-count">
                      {this.props.items ? this.props.items.counts['smartHomeDevice']: '-'}%
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <ProgressBar className="smart-home-device" now={this.props.items ? this.props.items.counts['smartHomeDevice'] : 0}/>   
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row">
                    <div className="col-md-8">
                      Home Security 
                    </div>
                    <div className="col-md-4 right-align device-count">
                      {this.props.items ? this.props.items.counts['homeSecurity'] : '-'}%
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <ProgressBar className="home-security" now={this.props.items ? this.props.items.counts['homeSecurity'] : 0}/>   
                    </div>
                  </div>  
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="col-md-8">
                      Entertainment 
                    </div>
                    <div className="col-md-4 right-align device-count">
                      {this.props.items ? this.props.items.counts['entertainment'] : '-'}%
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <ProgressBar className="entertainment" now={this.props.items ? this.props.items.counts['entertainment'] : 0}/>   
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row">
                    <div className="col-md-8">
                      Networking 
                    </div>
                    <div className="col-md-4 right-align device-count">
                      {this.props.items ? this.props.items.counts['networking'] : '-'}%
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <ProgressBar className="networking" now={this.props.items ? this.props.items.counts['networking'] : 0}/>   
                    </div>  
                  </div>  
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="col-md-8">
                      Computer | Phone | Printer 
                    </div>
                    <div className="col-md-4 right-align device-count">
                      {this.props.items ? this.props.items.counts['computersPhonesPrinters'] : '-'}%
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <ProgressBar className="computers-phones-printers" now={this.props.items ? this.props.items.counts['computersPhonesPrinters']  : 0}/>   
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row">
                    <div className="col-md-8">
                      Other 
                    </div>
                    <div className="col-md-4 right-align device-count">
                      {this.props.items ? this.props.items.counts['other'] : '-'}%
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <ProgressBar className="other" now={this.props.items ? this.props.items.counts['other'] : 0}/>   
                    </div>  
                  </div>  
                </div>
              </div>
            </div>
          </div>
        : <ServerError version2 height="266px" />
        }
      </div>
    )
  }
}
UserInfoCharts.propTypes = {
  guid: PropTypes.string,
  items: PropTypes.object,
  error: PropTypes.bool,
  dispatch: PropTypes.func
};

function mapStateToProps(state){
  return {
    items: state.UserInfoCharts.items.data,
    error: state.UserInfoCharts.error,
    loading: state.UserInfoCharts.loading
  }
}

export default connect(mapStateToProps)(UserInfoCharts);