import React from "react";
import Header from './CustomComponents/Header';
import CustomGreetings from './CustomComponents/Greetings'
import PropTypes from "prop-types";
import { hot } from "react-hot-loader";
import Routes from './Routes'

class App extends React.Component {
  constructor(props){
    super(props);
  }
  render() {
    if(this.props.authState == 'signedIn'){
      // let userName = this.props.authData.signInUserSession.idToken.payload.name,
      //     userGroups = this.props.authData.signInUserSession.idToken.payload['cognito:groups'];
      return (
        <div>
          <CustomGreetings {...this.props}/>
          <Header/>
          <Routes />
        </div>
      );
    } else {
      return null;
    }   
  }
}

App.propTypes = {
  children: PropTypes.element
};

export default hot(module)(App);
