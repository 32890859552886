export const FETCH_DATA_BEGIN = "FETCH_DATA_BEGIN";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";

export const TABLE_FETCH_DATA_BEGIN = "TABLE_FETCH_DATA_BEGIN";
export const TABLE_FETCH_DATA_SUCCESS = "TABLE_FETCH_DATA_SUCCESS";
export const TABLE_FETCH_DATA_FAILURE = "TABLE_FETCH_DATA_FAILURE";

export const USER_COUNT_FETCH_DATA_BEGIN = "USER_COUNT_FETCH_DATA_BEGIN";
export const USER_COUNT_FETCH_DATA_SUCCESS = "USER_COUNT_FETCH_DATA_SUCCESS";
export const USER_COUNT_FETCH_DATA_FAILURE = "USER_COUNT_FETCH_DATA_FAILURE";

export const SEARCH = "SEARCH"

export const USER_INFO_FETCH_DATA_BEGIN = "USER_INFO_FETCH_DATA_BEGIN";
export const USER_INFO_FETCH_DATA_SUCCESS = "USER_INFO_FETCH_DATA_SUCCESS";
export const USER_INFO_FETCH_DATA_FAILURE = "USER_INFO_FETCH_DATA_FAILURE";

export const USER_OVERVIEW_TABLE_FETCH_DATA_BEGIN = "USER_OVERVIEW_TABLE_FETCH_DATA_BEGIN";
export const USER_OVERVIEW_TABLE_FETCH_DATA_SUCCESS = "USER_OVERVIEW_TABLE_FETCH_DATA_SUCCESS";
export const USER_OVERVIEW_TABLE_FETCH_DATA_FAILURE = "USER_OVERVIEW_TABLE_FETCH_DATA_FAILURE";

export const USER_INFO_CHARTS_FETCH_DATA_BEGIN = "USER_INFO_CHARTS_FETCH_DATA_BEGIN";
export const USER_INFO_CHARTS_FETCH_DATA_SUCCESS = "USER_INFO_CHARTS_FETCH_DATA_SUCCESS";
export const USER_INFO_CHARTS_FETCH_DATA_FAILURE = "USER_INFO_CHARTS_FETCH_DATA_FAILURE";

export const TOOLTIP_STATUS = "TOOLTIP_STATUS"

export const AVERAGE_INTERNET_SPEED_FETCH_DATA_BEGIN = "AVERAGE_INTERNET_SPEED_FETCH_DATA_BEGIN";
export const AVERAGE_INTERNET_SPEED_FETCH_DATA_SUCCESS = "AVERAGE_INTERNET_SPEED_FETCH_DATA_SUCCESS";
export const AVERAGE_INTERNET_SPEED_FETCH_DATA_FAILURE = "AVERAGE_INTERNET_SPEED_FETCH_DATA_FAILURE";

export const AVERAGE_DEVICES_LATENCY_FETCH_DATA_BEGIN = "AVERAGE_DEVICES_LATENCY_FETCH_DATA_BEGIN";
export const AVERAGE_DEVICES_LATENCY_FETCH_DATA_SUCCESS = "AVERAGE_DEVICES_LATENCY_FETCH_DATA_SUCCESS";
export const AVERAGE_DEVICES_LATENCY_FETCH_DATA_FAILURE = "AVERAGE_DEVICES_LATENCY_FETCH_DATA_FAILURE";