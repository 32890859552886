import React, { Component } from 'react';

class NoData extends Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <div className="no-data-found">
        <p>No Data Found</p>
      </div>
    )
  }
}
export default NoData;
