import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./Pages/Home";
import UserOverview from "./Pages/UserOverview";
import NotFoundPage from "./Pages/NotFoundPage";
import DeviceSumary from './Pages/DeviceSummary';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/useroverview/:id" component={UserOverview} />
    <Route exact path="/devicesummary/:id" component={DeviceSumary} />
    <Route component={NotFoundPage} />
  </Switch>
);

export default Routes;