import React, { Component } from 'react';
import { connect } from "react-redux";
import LineChartsView from '../Performance/LineChartsView'
import PropTypes from 'prop-types';

class PerformanceCharts extends Component {
  constructor(props){
    super(props);
    this.state = {
      labels: [],
      downloadSpeedData : [],
      uploadSpeedData : [],
      bandwidthDemandData : []
    }
    this.updateTheStateInLifeCycleMethods = this.updateTheStateInLifeCycleMethods.bind(this);
  }
  UNSAFE_componentWillMount(){
    this.updateTheStateInLifeCycleMethods(this.props);
  } 
  UNSAFE_componentWillReceiveProps(nextProps){
    this.updateTheStateInLifeCycleMethods(nextProps);
  } 
  updateTheStateInLifeCycleMethods(props){
    let labels= [], uploadSpeedData = [], downloadSpeedData = [], bandwidthDemandData = [], dataArr = props.items ? props.items.data : [];
    if(dataArr.length > 0) {
      let objArr = dataArr;
        for(let data of objArr){
          labels.push(data.created_at.substr(-2));
          uploadSpeedData.push(data.avg_upload_speed || 0)
          downloadSpeedData.push(data.avg_download_speed|| 0);
          bandwidthDemandData.push(data.avg_bandwidth_demand || 0);
        }
        this.setState({
          labels: labels,
          downloadSpeedData : downloadSpeedData,
          uploadSpeedData : uploadSpeedData,
          bandwidthDemandData : bandwidthDemandData
        })
    }
  }
  render() {
    return (
      <div className="performance-charts">
      {!this.props.error &&
        <div className="row performance-charts-section-row">
          <div className="col">
            <h2 className='upload-speed-header'>Upload Speed</h2>
            <LineChartsView 
              className= "upload-speed-line-charts"
              data={this.state.uploadSpeedData}
              labels={this.state.labels}
              width={250}
              height={220}
              margin={{top: 10, bottom: 10, left: 100, right: 100}}
              outerRadius ={100}
              innerRadius={75}
              cornerRadius={2}
              daysbefore = {this.props.daysbefore}
            />
          </div>
          <div className="col">
            <h2 className='download-speed-header'>Download Speed</h2>
            <LineChartsView 
              className= "download-speed-line-charts"
              data={this.state.downloadSpeedData}
              labels={this.state.labels}
              width={250}
              height={220}
              margin={{top: 10, bottom: 10, left: 100, right: 100}}
              outerRadius ={100}
              innerRadius={75}
              cornerRadius={2}
              daysbefore = {this.props.daysbefore}
            />
          </div>
          <div className="col">
            <h2 className='bandwidth-demand'>Bandwidth Demand</h2> 
            <LineChartsView 
              className= "bandwidth-demand-line-charts"
              data={this.state.bandwidthDemandData}
              labels={this.state.labels}
              width={250}
              height={200}
              margin={{top: 10, bottom: 10, left: 100, right: 100}}
              outerRadius ={100}
              innerRadius={75}
              cornerRadius={2}
              daysbefore = {this.props.daysbefore}
            />
          </div>
        </div>
        }
      </div>
    );
  }
}
PerformanceCharts.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.object,
  error: PropTypes.bool,
  dispatch: PropTypes.func,
  rowsPerPage: PropTypes.number,
  history: PropTypes.object,
  guid: PropTypes.string,
  daysbefore: PropTypes.number
}
function mapStateToProps(state){
	return {
    items: state.AverageInternetSpeed.items.data,
    loading: state.AverageInternetSpeed.loading,
    error: state.AverageInternetSpeed.error
	}
}

export default connect(mapStateToProps)(PerformanceCharts);

