import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import PropTypes from 'prop-types';

import Subheader from '../../CustomComponents/Subheader';
import UserInfo from './UserInfo';
import UserOverviewPagination from './Devices/UserOverviewPagination';
import UserInfoCharts from './Devices/UserInfoCharts';
import Performance from './Performance';
import comingsoon from '../../../img/comingsoon.png'

class UserOverview extends Component {
    
  render(){
    let userInfoProps = {
      deviceCount : this.props.location.state.deviceCount,
      puckStatus: this.props.location.state.puckStatus,
      guid: this.props.match.params.id
    }
    return(
      <div>
        <Subheader text="User Overview" arrow={true} href="/"/>
        <div className="container">
          <div className="row padding-wrapper">
            <div className="col-3 user-info-wrapper">
              <UserInfo {...userInfoProps} />
            </div>
            <div className="col-9 tabs">
              <Tabs>
                <TabList>
                  <Tab>DEVICES</Tab>
                  <Tab>PERFORMANCE</Tab>
                  <Tab>EVENT ACTIVITY</Tab>
                </TabList>

                <TabPanel>
                  <UserInfoCharts 
                    data={[5, 2, 7, 1, 1, 3, 4, 9]}
                    width={600}
                    height={400}
                    margin={{top: 10, bottom: 10, left: 100, right: 100}}
                    outerRadius ={100}
                    innerRadius={150}
                    guid={this.props.match.params.id}
                  />
                  <UserOverviewPagination guid={this.props.match.params.id} {...userInfoProps}/>
                </TabPanel>
                <TabPanel>
                  <Performance {...userInfoProps}/>
                </TabPanel>
                <TabPanel>
                  <img src={comingsoon} className="comingsoon-img"/>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

UserOverview.propTypes = {
	match: PropTypes.object
}

export default UserOverview;