import React, {Component} from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import PerformanceCharts from './PerformanceCharts';
import AverageInternetSpeed from './AverageInternetSpeed';
import AverageDevicesLatency from './AverageDevicesLatency';
import {ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import * as InternetSpeeed from '../../../../actions/averageInternetSpeedActions';
import * as DeviceLatency from '../../../../actions/averageDevicesLatencyActions';
import Spinner from '../../../CustomComponents/Spinner';

class Performance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: false,
      daysbefore: this.props.daysbefore
    }
  }
  componentDidMount() {
    this.props.dispatch(InternetSpeeed.fetchDataUsingAxios(this.props.guid, this.props.daysbefore));
    this.props.dispatch(DeviceLatency.fetchDataUsingAxios(this.props.guid, this.props.daysbefore));
  }
  handleBtnClick(daysbefore){  
    this.setState ({
      daysbefore: Number(daysbefore)
    }, () => {
      this.props.dispatch(InternetSpeeed.fetchDataUsingAxios(this.props.guid, daysbefore));
      this.props.dispatch(DeviceLatency.fetchDataUsingAxios(this.props.guid, daysbefore));
    });
  }
  render() {
    let data = this.props.items ? this.props.items.data : [];
      return (
        <div className={`react-performance-tab ${this.props.loading ? 'position-relative' : ''}`}>
          {!this.props.error && data.length > 0 &&
          <div className="performance-tabs">
            <PerformanceCharts guid={this.props.guid} daysbefore = {this.state.daysbefore}/>
            <div className="days-filter">
              <ButtonToolbar>
                <ToggleButtonGroup type="radio" name="options" defaultValue={this.state.daysbefore}>
                  <ToggleButton value={7} onClick={() => this.handleBtnClick("7")}>PAST 7 DAYS</ToggleButton>
                  <ToggleButton value={14} onClick={() => this.handleBtnClick("14")}>PAST 14 DAYS</ToggleButton>
                  <ToggleButton value={30} onClick={() => this.handleBtnClick("30")}>PAST 30 DAYS</ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
            </div>
            <AverageInternetSpeed guid={this.props.guid}/>
            <AverageDevicesLatency guid={this.props.guid} deviceCount={this.props.deviceCount}/>
          </div>
          }
          {this.props.loading &&
              <Spinner/>
          }
        </div>
      );
  }
}
Performance.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.object,
  error: PropTypes.bool,
  dispatch: PropTypes.func,
  rowsPerPage: PropTypes.number,
  history: PropTypes.object,
  guid: PropTypes.string,
  daysbefore: PropTypes.number,
  deviceCount: PropTypes.number
}
Performance.defaultProps = {
  daysbefore: 7
}
function mapStateToProps(state){
	return {
    items: state.AverageInternetSpeed.items.data,
    loading: state.AverageInternetSpeed.loading,
    error: state.AverageInternetSpeed.error
	}
}

export default connect(mapStateToProps)(Performance);
