import * as C  from '../constants';
// import { getAverageDevicesLatency } from '../services';
const data = require('../json/AverageDevicesLatency.json');

export const fetchDataBegin = () => ({
  type: C.AVERAGE_DEVICES_LATENCY_FETCH_DATA_BEGIN
});

export const fetchDataSuccess = data => ({
  type: C.AVERAGE_DEVICES_LATENCY_FETCH_DATA_SUCCESS,
  payload: { data }
});

export const fetchDataFailure = error => ({
  type: C.AVERAGE_DEVICES_LATENCY_FETCH_DATA_FAILURE,
  payload: { error }
});

export function fetchDataUsingAxios() {
    return (dispatch) => {
        dispatch(fetchDataBegin());
        dispatch(fetchDataSuccess(data));

        // getAverageDevicesLatency(guid, daysbefore)
        //   .then( response =>{
        //         dispatch(fetchDataSuccess(data))
        //     })
        //   .catch(error => {
        //     dispatch(fetchDataFailure(error));
        //   })
    }
}
