import { withRouter } from "react-router-dom";
import React, { Component } from 'react';
import { connect } from "react-redux";
import ReactTable from "react-table";
import PropTypes from 'prop-types';
import { toCapitalize } from '../../../../utils/utils'
import { fetchDataUsingAxios } from '../../../../actions/userOverviewTableActions';
import Spinner from '../../../CustomComponents/Spinner'
import "react-table/react-table.css";
import ReactTooltip from 'react-tooltip';
import NoData from '../../../CustomComponents/NoData';

const modifyTableData = function(value){
  switch(value.toLowerCase()){
    case 'computers-phones-printers':
      return 'Computers | Phones | Printers';
    default:
      return value;
  }
}

class UserOverviewTable extends Component {
  handlePageClick(){
    this.props.dispatch(fetchDataUsingAxios())
  }
  handleClick(data){
    this.props.history.push({
      pathname: '/devicesummary/'+data.macAddress,
      state:{vendor: data.vendor, deviceName: data.deviceName, guid: this.props.guid, deviceCount: this.props.deviceCount, 
                category: data.category, puckStatus: this.props.puckStatus, deviceStatus: data.deviceStatus}
    })
  }
  render(){
      const columns = [
      {
        Header: "CATEGORY",
        accessor: "category",
        sortable: false,
        minWidth: 280,
        className: "rt-td-column",
        Cell: props => (
          <div>
            <span data-tip={props.value} title={props.value} className="userName rt-td-column-data align-left">{modifyTableData(props.value)}</span>
            <ReactTooltip />
          </div>
        )
      },
      {
        Header: "DEVICE NAME",
        accessor: "deviceName",
        sortable: false,
        minWidth: 200,
        className: "rt-td-column",
        Cell: props => <span className='rt-td-column-data align-left'>{modifyTableData(props.value)}</span>
      },
      {
        Header: "MAC ADDRESS",
        accessor: "macAddress",
        sortable: false,
        minWidth: 180,
        className: "rt-td-column text-center",
        Cell: props => <span className='rt-td-column-data'>{modifyTableData(props.value)}</span>
      },
      {
        Header: "VENDOR",
        accessor: "vendor",
        sortable: false,
        minWidth: 200,
        className: "rt-td-column",
        Cell: props => (
          <div>
            <span data-tip={props.value} className="vendor rt-td-column-data align-left">{modifyTableData(props.value)}</span>
            <ReactTooltip />
          </div>
        )
      },
      {
        Header: "DEVICE STATUS",
        accessor: "deviceStatus",
        sortable: false,
        minWidth: 220,
        className: "rt-td-column",
        Cell: cellInfo => (<div className="puck-status rt-td-column-data align-left position-relative">
                            <span className={cellInfo.value.toLowerCase() === 'active' ? 'active' : 'inactive'}></span>
                            <span className="rt-td-column-value">{toCapitalize(cellInfo.value)}</span>
                            <p className="arrowClick" onClick={() => this.handleClick(cellInfo.original)}><span></span></p>
                          </div>)
      }
    ]
    let data = this.props.items ? this.props.items.data : [];
    return(
      <div className="user-overview-table">
      {
        !this.props.error &&
        <ReactTable
          className="-striped -highlight"
          loading = {this.props.loading}
          columns = {columns}
          data = {data}
          pageSize = {this.props.rowsPerPage}
          loadingText = {<Spinner/>}
          noDataText = {this.props.loading ? "" : <NoData/>}
          resizable
          minRows={10}
          showPagination = {false}
        />
      }
      </div>
    )
  }
}
UserOverviewTable.defaultProps ={
  pages: 10
}
UserOverviewTable.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.object,
  error: PropTypes.bool,
  dispatch: PropTypes.func,
  rowsPerPage: PropTypes.number,
  history: PropTypes.object,
  guid: PropTypes.string,
  deviceCount: PropTypes.number,
  puckStatus: PropTypes.string,
  deviceStatus: PropTypes.string
}
function mapStateToProps(state){
	return {
    items: state.userOverviewTable.items.data,
    loading: state.userOverviewTable.loading,
    error: state.userOverviewTable.error
	}
}

export default withRouter(connect(mapStateToProps)(UserOverviewTable));
